// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/solution/foot-bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/home/button1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".flex-start[data-v-6a154934]{-webkit-box-pack:start;-ms-flex-pack:start;justify-content:start}.flex-between[data-v-6a154934],.flex-start[data-v-6a154934]{display:-webkit-box;display:-ms-flexbox;display:flex}.flex-between[data-v-6a154934]{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.flex-align[data-v-6a154934],.footers[data-v-6a154934]{-webkit-box-align:center;-ms-flex-align:center;align-items:center}.footers[data-v-6a154934]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:385px;background-size:cover;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:start}.conBox[data-v-6a154934]{width:1200px;margin:0 auto;padding:8.2% 0}.titles[data-v-6a154934]{font-size:48px;font-family:PingFang SC,PingFang SC-Semibold;font-weight:600;color:#0b1d30;text-align:center;height:70px}.group[data-v-6a154934]{padding:0 300px}.group-btn[data-v-6a154934]{width:300px;margin:auto;margin-top:25px}.btn-box[data-v-6a154934]{cursor:pointer;height:36px;padding:0 15px;line-height:36px;text-align:center;color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
module.exports = exports;
