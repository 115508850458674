<template>
  <transition name="fade">
    <div id="navBar" class="nav-bar" :class="activeClass===true ? 'active-class' : ''">
      <div class="conBox flex-between flex-align">
        <div class="flex-start flex-align">
          <a v-for="(item,index) in navList" :key="index" :class="activeIndex === index ? 'nav-item-active' : 'nav-item'" :href="'#'+item.id" @click="onClick(index)">{{ item.name }}</a>
        </div>
        <div v-show="from === 'yljk'" class="btn-box" @click="query">{{ btnText }} <i class="el-icon-right" style="margin-left:8px" /> </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      navList: [{
        name: '行业分析',
        id: 'hyfx_div'
      }, {
        name: '方案全景',
        id: 'faqj_div'
      }, {
        name: '平台功能',
        id: 'ptgn_div'
      }, {
        name: '适用场景',
        id: 'sycj_div'
      }],
      headHeight: 0,
      activeIndex: 0,
      activeClass: false,
      btnText: '',
      fileUlr: ''
    };
  },
  computed: {
  },
  watch: {
    from: {
      handler() {
        this.changeInfo();
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll, true);
      window.addEventListener('scroll', this.setFun, true);
      // 确保解决方案横幅的高度在元素渲染后被正确设置
      this.headHeight = document.getElementById('solutionBanner') ? document.getElementById('solutionBanner').offsetHeight : 0;
    });
  },
  created() {
    this.$nextTick(() => {
      this.headHeight = document.getElementById('solutionBanner').offsetHeight;
    });
  },
  methods: {
    changeInfo() {
      switch (this.from) {
        case 'Scheme':
          this.btnText = 'JiaPark手册下载';
          break;
        case 'ctjs':
          this.btnText = 'JiaAEC产品手册下载';
          break;
        case 'yljk':
          this.btnText = 'JiaHealth产品手册下载';
          this.fileUlr = 'https://static.zhongjiadata.com/images/20220527/1ad07abfd0e14137bd33217ab2c2ee0b.pdf';
          break;
        case 'tcgl':
          this.btnText = 'JiaMobility产品手册下载';
          break;
        default:
          break;
      }
    },
    handleScroll() {
      // 页面滚动距顶部距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= this.headHeight) {
        this.activeClass = true;
      } else {
        this.activeClass = false;
      }
    },
    onClick(index) {
      setTimeout(() => {
        this.activeIndex = index;
      }, 500);
    },
    setFun() {
      setTimeout(() => {
        this.pageScroll();
      }, 1500);
    },
    pageScroll() {
      const offsetTopArr = [];
      this.navList.forEach(element => {
        const el = document.getElementById(`${element.id}`);
        if (el) { // 确保元素存在
          offsetTopArr.push(el.offsetTop - 180);
        }
      });
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      this.activeIndex = navIndex;
    },

    query() {
      switch (this.from) {
        case 'Scheme':
          break;
        case 'ctjs':
          break;
        case 'yljk':
          window.open(this.fileUlr, '_blank');
          break;
        case 'tcgl':
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
}
.nav-bar {
  height: 50px;
  background: #fafafa;
}
.btn-box {
  cursor: pointer;
  height: 36px;
  padding: 0px 15px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  background-image: url('../../../assets/home/button1.png');
}
.nav-item,
.nav-item-active {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  text-align: left;
  color: #0b1d30;
  margin-right: 70px;
  line-height: 50px;
  cursor: pointer;
  position: relative;
}
.nav-item-active {
  color: #30a5ff;
}
.nav-item-active:after {
  position: absolute;
  content: '';
  width: 37px;
  height: 6px;
  border-radius: 3px;
  background: #30a5ff;
  bottom: -8px;
  left: 0;
  right: 0;
  margin: auto;
}
.active-class {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  height: 67px;
  .nav-item {
    line-height: 67px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
