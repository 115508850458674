<template>
  <div class="carousel">
    <img class="banner-img" :src="imgUrl">
    <div class="content">
      <div
        v-for="(item, index) in contentData"
        v-show="from === item.type"
        :key="index"
        class="box flex-between scheme-box flex-align"
      >
        <div style="width:500px">
          <div class="item-box">
            <span>{{ item.title }}</span>
          </div>
          <div class="text">{{ item.description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      contentData: [
        {
          type: 'Scheme',
          title: 'JDP | 产业园区',
          description: '产业园区是产业经济发展最重要的载体，基于JDP的园区数字化建设主要包含综合物业管理、低碳节能管理和政企营商服务等。'
        },
        {
          type: 'ctjs',
          title: 'JDP | 城投建设',
          description: '以数字化手段加强施工质量、安全、成本、进度管理，实行精细化管理，已经成为城投企业的一个重要任务。'
        },
        {
          type: 'yljk',
          title: 'JDP | 医疗健康',
          description: '病房是医院空间单元，同时也是业务单元，病房的智慧化建设关系到医疗健康整体行业的数字化。'
        },
        {
          type: 'tcgl',
          title: 'JDP | 停车管理',
          description: '停车难是城市交通拥堵的重要原因，通过数字化手段对城市公共停车的统筹管理，是智慧城市建设重要内容。'
        },
        {
          type: 'zwfw',
          title: '政务服务增值化',
          description: '为促进企业降低成本、增加收益、强化功能、加快发展，通过制度创新、数字赋能双轮驱动，政府、社会、市场三侧协同，进一步优化基本政务服务、融合增值服务，对政务服务体制机制、组织架构、方式流程、手段工具进行的变革性重塑。'
        },
        {
          type: 'zzgl',
          title: '证照管理',
          description: '为提升“互联网+政务服务“的效能，最大限度的精简和优化办事程序，证照系统进行政务服务创新改革，提供“统一制证+电子证照”服务于一体的证照管理服务体系，缩短了办事时间,提高政府数字化治理水平。'
        },
        {
          type: 'vision',
          title: '远程虚拟窗口',
          description: '远程虚拟窗口系统是利用远程视频实现居民和工作人员进行直接视频交流，实现远程取号、远程读卡、视频咨询及事项受理，让企业和群众就近享受政务服务，让数据多跑路群众少跑腿，实现高频事项乡镇就近办理等功能。'
        }
      ]
    };
  },
  computed: {
    imgUrl() {
      const imgMap = {
        'Scheme': require('@/assets/solution/cyyq/JIapark.png'),
        'ctjs': require('@/assets/solution/cyyq/JIaAEC.png'),
        'yljk': require('@/assets/solution/cyyq/JIaHealth.png'),
        'tcgl': require('@/assets/solution/cyyq/JIaMobility.png'),
        'zwfw': require('@/assets/solution/cyyq/JIaPublicService.png'),
        'zzgl': require('@/assets/solution/cyyq/JIaCertificate.png'),
        'vision': require('@/assets/solution/cyyq/JIaVision.png')
      };
      return imgMap[this.from] || '';
    }
  },
  created() { },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.carousel {
  width: 100%;
  height: 550px;
  position: relative;
  .content {
    position: absolute;
    top: 180px;
    left: 0;
    width: 100%;
    .box {
      width: 1280px;
      margin: 0 auto;
    }
    .title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 40px;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 32px;
      margin-left: 8px;
      margin-top: 32px;
    }
  }
}
/deep/ .el-carousel__container {
  height: 300px;
}
.scheme-box {
  .item-box {
    position: relative;
    // width: 264px;
    height: 94px;
    span {
      position: absolute;
      bottom: 10px;
      left: 14px;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: left;
    }
  }
  .right-img {
    width: 320px;
    height: 114px;
  }
}
@media screen and  ( max-width: 1500px ) {
  .carousel {
    height: auto;
  }
  .carousel .content {
    top: 80px;
  }
}
</style>
