<template>
  <div id="carouse" class="carouse">
    <div class="conBox">
      <!-- :style="{ 'background-image': 'url(' + cardList[cardIndex].bgMask + ')'}" -->
      <h1 class="h1-title">平台典型功能介绍</h1>
    </div>
    <div class="swiper-container swiper-no-swiping">
      <div class="car-tabs">
        <div class="swiper-pagination flex-start flex-align" />
      </div>
      <div class="swiper-wrapper">
        <div v-for="(item,index) in cardList" :key="index" class="swiper-slide">
          <div class="img-div" :style="{ 'background-image': 'url(' +item.img + ')'}" />
        </div>
      </div>
    </div>
    <div>
      <p class="carousel-text">{{ cardList[cardIndex].content }}</p>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css'; // 注意这里的引入
export default {
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tabList: ['综合安防', '能耗管理', '招商租赁', '企业服务'],
      cardList: [
        {
          img: require('@/assets/solution/cyyq/carousel_1.png'),
          content: '基于JialandPMS和AllOne IoT物联网核心，实现对以监控为主的物联设备和以海大宇等主流厂家设备系统的统一集成。围绕监控完成园区动态实时精准调取、人车通行、防盗报警、视频巡更等安防任务，支持基于AI边缘服务器实现周界防护、消防通道堵塞识别、人车轨迹跟踪等高级功能。'
        },
        {
          img: require('@/assets/solution/cyyq/carousel_2.png'),
          content: '对园区能耗计量系统数据进行采集，监测和统计水电气用量等能耗数据，实现远程抄表、数据监控、自主收费等，支持设定阈值、数据异常告警并下发工单等；基于能耗大数据提供全面用能盘查、精细化用能分析的综合能源管理服务，降低园区用能成本，提升水电气等使用、运营和安全水平。'
        },
        {
          img: require('@/assets/solution/cyyq/carousel_3.png'),
          content: '基于园区租售空间资产，从招商企业CRM管理、租赁管理、合同管理、财务管理全流程，以多种可视化方式清晰表达招商跟踪进度、房间租赁状态及园区招商孵化的各类组合数据，包括平面视图、单元视图、热力图、走势图以及数据列表等形式。房源信息以可视化体块形式统一管控，实现招商入驻全面线上化的效果。'
        },
        {
          img: require('@/assets/solution/cyyq/carousel_4.png'),
          content: '以为园区企业打造数字化营商环境和品牌宣传门户为目的，包含政策咨询、政策申报、企业档案库等。支持政策在线咨询、查询、筛选、推荐、申报等功能，使企业的政策申报更便捷、落地更高效。有效整合区域资源、形成企业服务合力、统一服务品牌，建设“一张网、全覆盖、多层级”的产业服务平台。'
        }
      ],
      cardIndex: 0,
      mySwiper: null
    };
  },
  computed: {},
  watch: {
    from: {
      handler() {
        this.changeInfo();
      },
      immediate: true
    }
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.swiperInit();
    }, 100);
  },
  methods: {
    changeInfo() {
      switch (this.from) {
        case 'Scheme':
          this.tabList = ['综合安防', '能耗管理', '招商租赁', '企业服务'];
          this.cardList = [
            {
              img: require('@/assets/solution/cyyq/carousel_1.png'),
              content: '基于JialandPMS和AllOne IoT物联网核心，实现对以监控为主的物联设备和以海大宇等主流厂家设备系统的统一集成。围绕监控完成园区动态实时精准调取、人车通行、防盗报警、视频巡更等安防任务，支持基于AI边缘服务器实现周界防护、消防通道堵塞识别、人车轨迹跟踪等高级功能。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_2.png'),
              content: '对园区能耗计量系统数据进行采集，监测和统计水电气用量等能耗数据，实现远程抄表、数据监控、自主收费等，支持设定阈值、数据异常告警并下发工单等；基于能耗大数据提供全面用能盘查、精细化用能分析的综合能源管理服务，降低园区用能成本，提升水电气等使用、运营和安全水平。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_3.png'),
              content: '基于园区租售空间资产，从招商企业CRM管理、租赁管理、合同管理、财务管理全流程，以多种可视化方式清晰表达招商跟踪进度、房间租赁状态及园区招商孵化的各类组合数据，包括平面视图、单元视图、热力图、走势图以及数据列表等形式。房源信息以可视化体块形式统一管控，实现招商入驻全面线上化的效果。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_4.png'),
              content: '以为园区企业打造数字化营商环境和品牌宣传门户为目的，包含政策咨询、政策申报、企业档案库等。支持政策在线咨询、查询、筛选、推荐、申报等功能，使企业的政策申报更便捷、落地更高效。有效整合区域资源、形成企业服务合力、统一服务品牌，建设“一张网、全覆盖、多层级”的产业服务平台。'
            }
          ];
          break;
        case 'ctjs':
          this.tabList = ['全域监控', '项目管理', '工作流审批', '实名制管理'];
          this.cardList = [
            {
              img: require('@/assets/solution/cyyq/carousel_ctjs_1.png'),
              content: '通过在工地的人车出入口、物料区、加工区、地下室、作业区、生活区以及塔吊等重点区域和全局视野处部署相关型号监控设备，实时视频监控，查看现场施工情况，记录项目进度，检视违规作业情况。同时结合监控边缘AI服务器，实现对人员考勤和轨迹跟踪、安全帽安全衣监测、防火监控等智能化功能。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_ctjs_2.png'),
              content: '以项目为中心，基于JiaAEC工作流审批通过人员、进度、成本、质量、安全、采购、合同等管理模块，实现项目团队的在线协作，打破信息孤岛。项目数据与信息的数字化将逐步积累，通过对历史项目的数据分析和利用，对沉淀企业成本数据库、项目管理经验有极大的作用。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_ctjs_3.png'),
              content: '通过在工地出入口部署实名认证系统、监控视频的AI分析系统，实现劳务人员入场的双防管理，工人进入工地时，必须通过闸机系统的人脸识别。支持北向接入住建部门国家劳务实名制系统，实现对劳务管理的合法化和标准化，从而推动工程建设安全性、成本质量精细化管控。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_ctjs_4.png'),
              content: '通过在工地出入口部署实名认证系统、监控视频的AI分析系统，实现劳务人员入场的双防管理，工人进入工地时，必须通过闸机系统的人脸识别。支持北向接入住建部门国家劳务实名制系统，实现对劳务管理的合法化和标准化，从而推动工程建设安全性、成本质量精细化管控。'
            }
          ];
          break;
        case 'yljk':
          this.tabList = ['病房呼叫', '床边交互', '护士站交互', '医生看板'];
          this.cardList = [
            {
              img: require('@/assets/solution/cyyq/carousel_yljk_1.png'),
              content: '病房呼叫系统基于JialandPMS和AllOne IoT物联网核心，实现医护人员与住院患者之间直接、可靠的信息联络，对医院病床和呼叫设备统一进行管理，并引入智能穿戴设备的应用，护士在动态情况下也能够接收到病房患者的及时呼叫提醒。可与医院其他医护系统互联，实现护理信息一体化，提高护理工作的自动化、数字化和人性化水平。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_yljk_2.png'),
              content: '床边智慧交互系统使用床旁智能交互屏配以机械摇臂的方式，方便患者和医护人员在床旁方便地完成交互操作，包括患者模式和医护模式。利用智能床垫、输液检测仪、智能多体征采集仪等智能医疗设备，实现包括患者评估、睡眠分析、健康宣教、护理计划、生命体征监测、输液状态、环境传感、娱乐模式等功能。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_yljk_3.png'),
              content: '护士站智慧交互系统通过放在护士站和医生办公室的智能交互大屏，与第三方系统数据互联互通，实现病区概况、患者信息、今日治疗、备忘录、交班信息、呼叫响应、健康宣教、风险预警等信息，帮助医护人员快速获取工作重点，优化工作流程，从而提高效率。通过智能交互大屏，实现院内数据统一整合、统一显示。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_yljk_4.png'),
              content: '医生智慧看板系统可实现病区信息“可视化”，替代传统手写板，确保病区信息的及时更新和准确传递，将护士、医生所需了解的相关信息一一体现。包括重点提示栏、医生一览表、手术概述、患者一览表、医嘱查询等核心功能，通过自身物联网智慧感知信息集成服务平台融合医院多个系统数据，与院内数据互联互通。'
            }
          ];
          break;
        case 'tcgl':
          this.tabList = ['设备中心', '无人值守', '数据大屏', '运营中心'];
          this.cardList = [
            {
              img: require('@/assets/solution/cyyq/carousel_tcgl_1.png'),
              content: '基于JialandPMS和AllOne IoT物联网核心，提供设备的接入和管理，设备的接入类型包括以海康、科拓等主流第三方系统，以及支持GB28181协议的设备、支持交通部标协议(JT808)的设备、支持NB协议接入的设备。同时提供组织管理（增删改导入导出上下移动等）、设备管理（增删改启用禁用）和监控点管理等系统管理功能。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_tcgl_2.png'),
              content: '在无感支付趋势背景下，对于城市级数量巨大的停车资源管理，无人值守模式是节约成本的重要手段。若车辆出入车道出现无法自主处理的异常情况，可以通过在出入口的无人值守票箱的呼叫按钮向远程协助中心求助，远程协助中心在接到呼叫请求后，通过对讲、照片及车辆信息核实情况后通过通过中心客户端的修改车牌、异常放行等功能来实现远程异常处理。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_tcgl_3.png'),
              content: '系统提供数据汇聚、数据治理、数据服务等能力，对各类数据进行统一汇聚、治理及可视化展现，消除数据孤岛，规范与标准化数据，并通过数据共享服务对上层应用提供北向开放接口。业务平台可以调用标准化接口获取数据，并可以基于此能力快速提供建立对外服务能力，运营人员也可以基于基础数据做数据统计和分析。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_tcgl_4.png'),
              content: '系统提供产品管理、运营统计分析的服务能力，方便上层停车业务平台进行产品的注册、产品信息的配置、项目信息的配置及全局统计分析（按设备、用户、设备在线等维度），主要包括计费规则、计费包、特殊策略、黑白名单、包期管理等常规运营管理功能和积分规则、兑换商城、优惠券管理等商业化运营管理功能。'
            }
          ];
          break;
        case 'zwfw':
          this.tabList = ['企业码', '企业线上综合服务平台', '政策直达', '政策兑付'];
          this.cardList = [
            {
              img: require('@/assets/solution/cyyq/carousel_zw_1.png'),
              content: '作为“一网通办”的重要举措，“企业码”是企业数据场景化应用的入口。企业码汇聚各业务线涉企数据，连结线上线下场景，是企业的“电子档案”。同时也是企业获取政府服务的绿色通道、产业合作的协同平台以及数据驱动的应用系统。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_zw_2.png'),
              content: '综合多项企业在线服务，利用数字化工具为企业服务提供数字赋能。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_zw_3.png'),
              content: '通过直接将政策信息传递给相关群体，提高政策的知晓率和执行效率。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_zw_4.png'),
              content: '为企业提供政策咨询、申报、兑现“一站式、全流程”服务，打通政策落地“最后一公里”，为广大中小企业和个体工商户“输血补气”。'
            }
          ];
          break;
        case 'zzgl':
          this.tabList = ['目录管理', '制证签发', '证照共享', '领导视图'];
          this.cardList = [
            {
              img: require('@/assets/solution/cyyq/carousel_zz_1.png'),
              content: '实现证照目录发布、审核，对电子证照统一编码、分类和元数据管理，支持自定义可视化拖拽的方式进行照面元数据、电子印章、二维码等排版，对目录的查询以及快速检索及用证申请。证照目录以服务事项为基础，能够管理事项与证照 、证照与证照之间的关联关系、证照的使用范围和使用要求。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_zz_2.png'),
              content: '提供在线的证照全生命周期管理，包括证照信息汇集、证照打印、证照盖章、证照归档、电子证照签发、发证管理、挂失、废止、年检、删除等功能。并借助指纹识别、平台指令、刷卡启动、区块链等技术保证实体证照出证安全及电子证照的签发安全。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_zz_3.png'),
              content: '对第三方应用系统进行授权管理，管理各类业务系统用证信息及用证审批信息，共享证照信息并提供证照核验、查询、调取、比对、下载等功能，简化了事项申报流程，为群众实现减证免证、无证办理提供数据支撑，提高了政府的办事效率和服务水平,优化了营商环境。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_zz_4.png'),
              content: '通过大数据技术，归类、清洗、分析，提供对证照多维度统计分析和报表功能，快速统计部门科室月度、年度证照发放量，全面了解各部门工作情况，并提供对电子证照查阅、证照签发率、使用率、在线申办使用、业务在线查验、调度等使用情况按时间和证照类别等多维度的统计分析和报表功能。'
            }
          ];
          break;
        case 'vision':
          this.tabList = ['中心端', '社区端（网点）', '系统后端', '建设效果'];
          this.cardList = [
            {
              img: require('@/assets/solution/cyyq/carousel_js_1.png'),
              content: '工作人员登录远程平台后，有社区群众发起远程办事情况后，电脑有弹窗提醒，方便工作人员切换到桌面或是其他软件后也能第一时间收到办事请求。接通后，双向音视频通信，工作人员指导进行办事操作。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_js_2.png'),
              content: '办事群众工作台上选择办理事项取号，刷身份证实名取号，输入手机号码接收叫号短信。叫号后再工作人员的指导下完成办事操作。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_js_3.png'),
              content: '系统后台实时监管整个办事过程，从窗口叫号时开始录制，业务受理结束时停止录制。系统对窗口工作人员的办事过程进行分析，将办事行为纳入绩效考核内容中，对差评、着装规范，在岗情况、文明用语、窗口异常（吵架，看手机、聊天）等情况进行监管，并作出相应提醒，给群众提供更好的服务。'
            },
            {
              img: require('@/assets/solution/cyyq/carousel_js_4.png'),
              content: '推动“一网通办”向基层延伸，深化乡镇“家门口”政务服务圈建设，强化镇村两级帮办代办工作。最大程度做到“数据多跑路，群众少跑腿”，真正实现了政务“加速度”、服务“零距离”。'
            }
          ];
          break;
        default:
          break;
      }
    },
    swiperInit() {
      const that = this;
      this.mySwiper = new Swiper('.swiper-container', {
        slidesPerView: 1.8,
        spaceBetween: 0,
        centeredSlides: true,
        autoplay: {
          pauseOnMouseEnter: true,
          delay: 5000
        },
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function(index, className) {
            return '<span class="' + className + '">' + that.tabList[index] + '</span>';
          }
        },
        on: {
          slideChange() {
            that.changeNum(this.realIndex);
          }
        }
      });
      this.mySwiper.el.onmouseover = function() {
        that.mySwiper.autoplay.stop();
      };
      this.mySwiper.el.onmouseleave = function() {
        that.mySwiper.autoplay.start();
      };
    },
    handleClick(index) {
      this.cardIndex = index;
      // 手动切换幻灯片
      this.mySwiper.realIndex = this.cardIndex;
    },
    changeNum(index) {
      this.cardIndex = index;
    }
  }
};
</script>

<style scoped lang="scss">
.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
}
.h1-title {
  font-size: 40px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  text-align: left;
  color: #0b1d30;
  text-align: center;
  padding-top: 80px;
  margin-bottom: 50px;
}
.carouse {
  padding-bottom: 80px;
  background-image: url('../../../assets/solution//cyyq/carousel_mask_1.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.car-tabs {
  width: 800px !important;
  margin: 0 auto;
  border-bottom: 1px solid #dedede;
  margin-bottom: 14px;
  .swiper-pagination {
    position: inherit;
    z-index: 0px;
  }
  /deep/.swiper-pagination-bullet {
    width: inherit;
    height: inherit;
    border-radius: 0;
    background: transparent;
    opacity: 1;
    flex: 1;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #333;
    padding-bottom: 15px;
    position: relative;
    cursor: pointer;
  }
  /deep/ .swiper-pagination-bullet-active{
    flex: 1;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #30a5ff;
    padding-bottom: 15px;
    position: relative;
    cursor: pointer;
    transition-duration: .3s, 1.5s;
    -webkit-transition-duration: .3s, 1.5s;
  }
  /deep/ .swiper-pagination-bullet-active::after {
    position: absolute;
    content: '';
    width: 37px;
    height: 6px;
    border-radius: 3px;
    background: #30a5ff;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.carousel-text {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #666666;
  line-height: 28px;
  width: 1000px;
  margin: 0 auto;
}
.swiper-container {
  width: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  transition: 300ms;
  transform: scale(0.8);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}
.img-div{
  margin:  auto;
  width: 54vw;
  height: calc(54vw*0.64);
  min-width: 720px;
  min-height: calc(720px*0.64);
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
