<template>
  <div class="sycj">
    <div class="img-boxs">
      <div class="el-rows first flex-between flex-align">
        <div v-for="(item,index) in info.first" :key="index" class="item-img">
          <el-image :src="item.img" alt="" :fit="'cover'" />
          <div class="item-img-text">
            <p class="title">{{ item.title }}</p>
            <p class="sub-title">{{ item.sub_title }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="img-boxs">
      <div class="el-rows second flex-between flex-align">
        <div v-for="(item,index) in info.second" :key="index" class="item-img">
          <el-image :src="item.img" alt="" :fit="'cover'" />
          <div class="item-img-text">
            <p class="title">{{ item.title }}</p>
            <p class="sub-title">{{ item.sub_title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      info: {
        first: [],
        second: []
      }
    };
  },
  computed: {
  },
  watch: {
    from: {
      handler() {
        this.changeInfo();
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    changeInfo() {
      switch (this.from) {
        case 'Scheme':
          this.SchemeFun();
          break;
        case 'ctjs':
          this.ctjsFun();
          break;
        case 'yljk':
          this.yljkFun();
          break;
        case 'tcgl':
          this.tcglFun();
          break;
        case 'zwfw':
          this.zwFun();
          break;
        case 'zzgl':
          this.zzFun();
          break;
        case 'vision':
          this.jsFun();
          break;
        default:
          break;
      }
    },
    SchemeFun() {
      this.info = {
        first: [{
          'img': require('@/assets/solution/cyyq/sycj_1.png'),
          'title': '科技园区',
          'sub_title': 'Sci-Tech Park'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_2.png'),
          'title': '创意园区',
          'sub_title': 'Creative Park'
        }],
        second: [{
          'img': require('@/assets/solution/cyyq/sycj_3.png'),
          'title': '企业园区',
          'sub_title': 'Enterprise Park'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_4.png'),
          'title': '工业园区',
          'sub_title': 'Industrial park'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_5.png'),
          'title': '产业园区',
          'sub_title': 'Industrial park'
        }]
      };
    },
    ctjsFun() {
      this.info = {
        first: [{
          'img': require('@/assets/solution/cyyq/sycj_ctjs_1.png'),
          'title': '住建部门',
          'sub_title': 'Housing and Construction Department'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_ctjs_2.png'),
          'title': '城建投公司',
          'sub_title': 'Urban Construction Investment Company'
        }],
        second: [{
          'img': require('@/assets/solution/cyyq/sycj_ctjs_3.png'),
          'title': '交建投公司',
          'sub_title': 'CCIC'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_ctjs_4.png'),
          'title': '勘察设计公司',
          'sub_title': 'survey and design company'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_ctjs_5.png'),
          'title': '工程建设公司',
          'sub_title': 'Engineering construction company'
        }]
      };
    },
    yljkFun() {
      this.info = {
        first: [{
          'img': require('@/assets/solution/cyyq/sycj_yljk_1.png'),
          'title': '综合医院',
          'sub_title': 'General Hospital'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_yljk_2.png'),
          'title': '社区医院',
          'sub_title': 'Community Hospital'
        }],
        second: [{
          'img': require('@/assets/solution/cyyq/sycj_yljk_3.png'),
          'title': '康复医院',
          'sub_title': 'Rehabilitation hospital'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_yljk_4.png'),
          'title': '疗养院',
          'sub_title': 'Nursing home'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_yljk_5.png'),
          'title': '敬老院',
          'sub_title': 'Rest house'
        }]
      };
    },
    tcglFun() {
      this.info = {
        first: [{
          'img': require('@/assets/solution/cyyq/sycj_tcgl_1.png'),
          'title': '平面封闭式停车场',
          'sub_title': 'Flat enclosed parking lot'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_tcgl_2.png'),
          'title': '地下停车场',
          'sub_title': 'Underground Parking Lot'
        }],
        second: [{
          'img': require('@/assets/solution/cyyq/sycj_tcgl_3.png'),
          'title': '路边泊车位',
          'sub_title': 'Off-street parking'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_tcgl_4.png'),
          'title': '立体停车库',
          'sub_title': 'Three-dimensional parking garage'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_tcgl_5.png'),
          'title': '新能源充电车位',
          'sub_title': 'New energy charging parking space'
        }]
      };
    },
    zwFun() {
      this.info = {
        first: [{
          'img': require('@/assets/solution/cyyq/sycj_zw_1.png'),
          'title': '行政审批局',
          'sub_title': 'Administrative Approval Bureau'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_zw_2.png'),
          'title': '政务大厅',
          'sub_title': 'Government Affairs Hall'
        }],
        second: [{
          'img': require('@/assets/solution/cyyq/sycj_zw_3.png'),
          'title': '经信局',
          'sub_title': 'Bureau of Economic and Information Technology'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_zw_4.png'),
          'title': '大数据局',
          'sub_title': 'Data Bureau'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_zw_5.png'),
          'title': '改革办',
          'sub_title': 'Reform Office'
        }]
      };
    },
    zzFun() {
      this.info = {
        first: [{
          'img': require('@/assets/solution/cyyq/sycj_zz_1.png'),
          'title': '行政审批局',
          'sub_title': 'Administrative Approval Bureau'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_zz_2.png'),
          'title': '公安厅',
          'sub_title': 'Public Security Department'
        }],
        second: [{
          'img': require('@/assets/solution/cyyq/sycj_zz_4.png'),
          'title': '卫建委',
          'sub_title': 'Health commission'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_zz_3.png'),
          'title': '教育局',
          'sub_title': 'Education Bureau'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_zz_5.png'),
          'title': '工商局',
          'sub_title': 'Industry and Commerce Bureau'
        }]
      };
    },
    jsFun() {
      this.info = {
        first: [{
          'img': require('@/assets/solution/cyyq/sycj_js_1.png'),
          'title': '政府服务大厅',
          'sub_title': 'Government service hall'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_js_2.png'),
          'title': '便民服务中心',
          'sub_title': 'Convenience Service Center'
        }],
        second: [{
          'img': require('@/assets/solution/cyyq/sycj_js_4.png'),
          'title': '人社局',
          'sub_title': 'Human Resources and Social Security'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_js_3.png'),
          'title': '医保局',
          'sub_title': 'Medical Insurance Bureau'
        }, {
          'img': require('@/assets/solution/cyyq/sycj_js_5.png'),
          'title': '社区服务中心',
          'sub_title': 'Community Service Center'
        }]
      };
    }
  }
};
</script>

<style scoped lang="scss">
.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.sycj {
  .img-boxs {
    width: 100%;
    height: 200px;
    .el-rows {
      width: 100%;
      height: 200px;
    }
    .first {
      .el-image {
        width: 588px !important;
        height: 200px;
        border-radius: 10px;
      }
    }
    .second {
      margin-top: 24px;
      .el-image {
        width: 384px !important;
        height: 200px;
        border-radius: 10px;
      }
    }
    .item-img {
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      &:hover {
        /deep/ .el-image {
          transform: scale(1.2);
          transition: 1s;
        }
      }
      .item-img-text {
        position: absolute;
        text-align: center;
        height: 50px;
        margin: auto;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 100px;
        p {
          margin: 0px;
          color: #ffffff;
          font-family: PingFang SC, PingFang SC-Semibold;
        }
        .title {
          font-size: 24px;
          font-weight: 600;
        }
        .sub-title {
          font-size: 12px;
          font-weight: 500;
          margin-top: 2px;
        }
      }
    }
  }
}
.sycj {
  padding-bottom: 80px;
}
</style>
